//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';
import NhstUserMixin from '@/components/mixin/NhstUserMixin';
import SeoMixin from '@/components/mixin/SeoMixin';
import AdobeAnalyticsMixin from '@/components/mixin/AdobeAnalyticsMixin';
import PageRefreshMixin from '@/components/mixin/PageRefreshMixin';
import dataDomainScript from '../../config/data_domain_script.json';

export default {
  mixins: [NhstUserMixin, SeoMixin, AdobeAnalyticsMixin, PageRefreshMixin],
  httpHeaders: ({env}) => ({
    'Cache-Control': 'max-age=0,s-maxage=1800',
    'xkey': env.NODE_ENV.startsWith('test') ? 'e-5-1-619615fd47508fb3487db0a5' : 'e-5-1-6180f787515496fa828a08cb'
  }),
  data() {
    return {
      env: 'prod',
      slots: {},
      adConfig: {},
    };
  },
  async fetch({store, params}) {
    await store.dispatch(
      'getEditionContent',
      (params.category !== 'frontpage' ? params.category : 'frontpage-top') ||
      'frontpage-top'
    );
  },
  head() {
    let script = [];
    if (!this.skipModules.includes('onetrust')) {
      script = [
        {
          hid: 'onetrust',
          src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
          'data-language': 'no',
          type: 'text/javascript',
          charset: 'UTF-8',
          'data-domain-script': dataDomainScript[this.nodeEnv],
          defer: true
        }
      ]
    }
    return {
      script
    };
  },
  computed: {
    ...mapState(['topEditionResponse', 'placeholdersAds', 'isShowingOnMobileApp', 'skipModules', 'nodeEnv', 'locals', 'scriptLoaded']),
  },
  watch: {
    'scriptLoaded.satelliteScript': {
      handler(newValue) {
        if(newValue === true && process.client) {
          this.defineAamsegments();
        }
      },
      immediate: true,
      deep: true
    }
  },
  beforeMount() {
    this.defineCookieSettingsActions();
    this.defineLiveWrappedAds();
  },
  methods: {
    defineAamsegments() {
      // satellite script is deferred, that`s way gpt script had to wait until satellite script loaded for sending gpt_aamsegments value as setTargeting.
      // It hampered the ad performance. So, we mitigate the dependency by saving gpt_aamsegments's key & value in localstorage and gpt script will read it from localstorage.
      // Changes will be reflecting in next request.

      if (typeof window.AamGpt !== 'undefined' &&
        typeof window.AamGpt.getCookie('gpt_aamsegments') != 'undefined') {
        window.localStorage.setItem('gpt_aamsegments_key', window.AamGpt.getKey('gpt_aamsegments'));
        window.localStorage.setItem('gpt_aamsegments_value', window.AamGpt.getValues('gpt_aamsegments'));
      } else {
        localStorage.removeItem('gpt_aamsegments_key');
        localStorage.removeItem('gpt_aamsegments_value');
      }
    },
    appendOneTrustCustomButton() {
      const buttonElement = document.createElement('button');
      buttonElement.id = 'ot-sdk-btn';
      buttonElement.className = 'ot-sdk-show-settings ot-sdk-custom-button';
      document.body.appendChild(buttonElement);
    },
    defineCookieSettingsActions() {
      const self = this;
      window.addEventListener('load', function () {
        self.appendOneTrustCustomButton();
        const cookieSettingBtn = document.querySelector('.cookies-popup-trigger');
        if (cookieSettingBtn) {
          cookieSettingBtn.addEventListener('click', function (e) {
            e.preventDefault();
            const element = document.querySelector('.ot-sdk-custom-button');
            if (element) {
              element.click();
            }
            window.setOneTrustSettingsUIEvents();
            return false;
          });

          if (location.hash === '#cookie-setting') {
            cookieSettingBtn.click();
          }
        }
      });
    },
    defineLiveWrappedAds() {
      const self = this;
      const globalKeywords = {};
      globalKeywords['mode'] = window.location.host.startsWith('beta') ? 'beta' : self.nodeEnv;
      globalKeywords['dn_loginstatus'] = (self.loginStatus && self.loginStatus.isLoggedIn) ? 'logged-in' : 'not-logged-in';
      globalKeywords['dn_section'] = ['forsiden'];
      globalKeywords['dn_topic'] = [];
      globalKeywords['dn_keyword'] = [];
      globalKeywords['dn_cookieinfo'] = window.nhst.cookieMessageShown && !window.nhst.shouldCheckIplogin ? 'Lukk' : '';

      const gpt_aamsegments_key = localStorage.getItem('gpt_aamsegments_key');
      const gpt_aamsegments_value = localStorage.getItem('gpt_aamsegments_value');
      if (gpt_aamsegments_key && gpt_aamsegments_value) {
        globalKeywords[gpt_aamsegments_key] = gpt_aamsegments_value;
      }

      if (self.isShowingOnMobileApp) {
        globalKeywords['app_name'] = 'DN';
      }
      if (navigator.userAgent.includes('DNApp') && navigator.userAgent.includes('Android')) {
        globalKeywords['app_environment'] = 'Android';
      } else if (navigator.userAgent.includes('DNApp') && navigator.userAgent.includes('iOS')) {
        globalKeywords['app_environment'] = 'iOS';
      }
      globalKeywords['screen.width'] = `${screen.width}`;
      globalKeywords['cpp'] = 'false';
      globalKeywords['dn_testads'] = this.getTestAdStatus();
      window.lwhb = window.lwhb || {cmd: []};
      // using googletag for slot's event listener'
      window.googletag = window.googletag || {cmd: []};

      window.lwhb.cmd.push(function () {
        window.lwhb.adServerKeywords(globalKeywords);
      });

      let parallaxEntryCount = 0;

      window.googletag.cmd.push(function () {
        window.googletag.pubads().setCentering(true);
        window.googletag.pubads().addEventListener('slotRenderEnded', function (event) {
          try {
            const slotElement = document.querySelector(`#${event.slot.getSlotElementId()}`);
            if (!event.isEmpty) {
              let siblings = slotElement.parentElement.querySelectorAll(':scope > label.dfp-ad-label');
              if (siblings != null && !siblings.isEmpty) {
                siblings.forEach((s) => s.classList.remove('is-hidden'));
              }
            }
            if (event.slot.getSlotElementId() === 'dn_toppbanner-forside-desktop' && !event.isEmpty) {
              slotElement.classList.add('dfp-ad-top-reduce-height');
            }

            const addScrollMonitorWatcherFor = (element) => {
              const elementWatcher = scrollMonitor.create(element, 150);
              const targets = document.querySelectorAll('.sticky-wrapper');
              elementWatcher.enterViewport(function () {
                parallaxEntryCount++;
                if (parallaxEntryCount > 0) {
                  targets.forEach((target) => {
                    target.classList.add('sticky-wrapper-hide');
                  });
                }
              });
              elementWatcher.exitViewport(function () {
                parallaxEntryCount--;
                if (parallaxEntryCount <= 0) {
                  targets.forEach((target) => {
                    target.classList.remove('sticky-wrapper-hide');
                  });
                }
              });
            }

            const handleParallaxScrollClasses = () => {
              const flyingCarpetElement = slotElement.parentElement.querySelector('.flyingCarpetHolder');
              const adnmInterScrollElement = slotElement.parentElement.querySelector('.adnm-interscroll-parent');
              const butterflyHolderElement = slotElement.parentElement.querySelector('.butterflyHolder');
              if (flyingCarpetElement) {
                addScrollMonitorWatcherFor(flyingCarpetElement);
              }
              if (adnmInterScrollElement) {
                addScrollMonitorWatcherFor(adnmInterScrollElement);
              }
              if (butterflyHolderElement) {
                addScrollMonitorWatcherFor(butterflyHolderElement)
              }
              if (flyingCarpetElement || adnmInterScrollElement || butterflyHolderElement) {
                clearTimeout(parallaxScrollTimeoutId);
                clearInterval(parallaxScrollIntervalId);
              }
            }

            const parallaxScrollIntervalId = setInterval(handleParallaxScrollClasses, 100);

            const parallaxScrollTimeoutId = setTimeout(function () {
              clearInterval(parallaxScrollIntervalId);
            }, 7000);

            if (event.slot.getSlotElementId() === 'dn_toppbanner-forside-desktop') {
              document.querySelectorAll('.sticky-wrapper .dfp-ad').forEach((ad) => ad.classList.remove('opacity-0'));
              if (!event.isEmpty) {
                slotElement.classList.add('dfp-ad-top-reduce-height');
              }
            }
          } catch (err) {
            console.error('Unable to set up watcher:::', err);
          }
        });
      });
    },
    getTestAdStatus () {
      // Split document.cookie by semicolons into an array
      let cookies = document.cookie.split(';');
      let cookieName = 'DN_TESTADS';
      // Loop through the array of cookies
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim(); // Trim any leading or trailing whitespace
        // Check if the cookie starts with the cookieName followed by '='
        if (cookie.indexOf(cookieName + '=') === 0) {
          let parts = cookie.split('=');
          // Trim leading and trailing whitespace from name and value
          let value = parts[1].trim();
          return value;
        }
      }

      return 'false'; // Cookie not found, return false
    }
  }
};
